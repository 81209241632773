h3,
h4 {
   font-weight: bold;

   @include breakpoint(medium) {
      font-weight: normal;
   }
}

.ind {
   margin-left: 1em;
}

.NoBullet {
   list-style-type: none;
}

span.b {
   font-weight: bold;
}

.underline,
span.u {
   text-decoration: underline;
}

.LgWord {
   float: left;
   font-size: xx-large;
   font-weight: bold;
   margin-right: 15px;
}

.red,
.rb,
.warning,
.error {
   color: $myRed;
}

.rb {
   font-weight: bold;
}

.ib {
   text-align: center;
   display: inline-block;
   line-height: 1;
}

span.normal {
   font-style: normal;
}

span.head {
   font-weight: bold;
   margin-right: 1em;
}

span.halfSize {
   font-size: 50%;
}

.center {
   text-align: center;
}

.block-center {
   margin: 0 auto;
}

p.verses {
   margin-bottom: 0;
   font-weight: bold;
}

.first {
   margin-top: 0;
}

blockquote {
   line-height: 1.5;
   margin: 1em 1.25em;
   font-style: italic;
   color: $black;
   border: 0;
   padding: 0;
}

p.quote {
   font-style: italic;
   margin: 0 1.25em 1em 1.25em;
   font-size: 95%;
}

p.indent {
   margin: 0 1.25em 1em 1.25em;
   font-size: 95%;
}

dl dt {
   margin-top: 1em;
   margin-bottom: 0;
}

dd {
   margin-top: 0;
}

td {
   vertical-align: top;

   &.symbol {
      width: 5em;
   }
}

.nml {
   font-style: normal;
   margin-right: .5em;
}

.norm {
   font-style: normal;
}

.margin-top-1 {
   margin-top: 1em;
}

cite {
   font-style: normal;
   font-weight: 550;
   font-size: 1em;
   text-align: left;
}

p,
blockquote,
div.callout,
li {
   -webkit-hyphens: auto;
   // -moz-hyphens: auto;
   // -moz-hyphenate-limit-chars: 6 3 3;
   // -moz-hyphenate-limit-lines: 2;
   // -moz-hyphenate-limit-last: always;
   // -moz-hyphenate-limit-zone: 6%;
   -ms-hyphens: auto;
   // -ms-hyphenate-limit-chars: 6 3 3;
   // -ms-hyphenate-limit-lines: 2;
   // -ms-hyphenate-limit-last: always;
   // -ms-hyphenate-limit-zone: 6%;
   hyphens: auto;
   // hyphenate-limit-chars: 6 3 3;
   // hyphenate-limit-lines: 2;
   // hyphenate-limit-last: always;
   // hyphenate-limit-zone: 6%;
   // text-align: justify;
}

p.left,
li.left {
   text-align: left;
}