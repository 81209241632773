$sm-perct : 90%;
$med-perct: 80%;
$lg-perct : 60%;

body {
   background-image: url("/assets/img/write013.jpg");
   background-repeat: repeat;
   font-size: calc(1rem + 0.5vw);
}

body, html {
   scroll-padding-top: 3rem;
   @include breakpoint(medium) {
      scroll-padding-top: 4rem;
   }
}

.wrap {
   width: 100%;
   max-width: 960px;
   margin: 0 auto;
}

.container {
   width: $sm-perct;
   margin: 0 auto;

   @if $med-perct !=$sm-perct {
      @include breakpoint(medium) {
         width: $med-perct;
      }
   }


   @include breakpoint(large) {
      width: $lg-perct;
      max-width: 700;
   }
}

header.banner {
   width: 100%;
   background-color: $myMaroon;
   padding: 1em 0;
   margin: 0 0 0 0;
   box-shadow: 0px 8px 5px $dark-gray;

   h1,
   h2 {
      color: $yellow;
   }
}

table.rim {
   border: 2px solid $black;
}

@mixin border-radius($list) {
   border-radius: $list;
   -moz-border-radius: $list;
   -webkit-border-radius: $list;
}

.callout.acoustics {
   background-color: $light-gray;
   margin-bottom: 1em;
}

.callout {
   @include border-radius(8px);
   padding: 1.25em;
}

button.close-button {
   span {
      color: $medium-gray;
   }

   outline-width: 0;
}

cite {
   font-size: 1em;
   font-style: normal;
   font-weight: normal;
   color: $black;
}

a:hover,
input[type="submit"]:hover {
   filter: brightness(140%);
}

.button {
   $button-background: $myBlue;
   $button-background-hover: $myBlue;
}

div.app1 {

   a:link,
   a:visited,
   a:hover {
      color: Magenta;
   }
}

// :target::before { content: ''; display: block; height: 3.25rem; margin-top: 3.25rem;}