div.card-section ul.pagination {
   padding: 2em 0 0 0;
   margin: 0;

   i {
      font-size: 1.5em;
      color: $white;
   }

   a.button {
      background: $myBlue;
   }

}