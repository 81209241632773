.input_submit {
   background-color: $myBlue;
   color: $white;
   padding: .5em;
   margin-top: 1em;
   font-size: 1em;
   @include border-radius(6px);
}

div.org {
   border: 0 !important;
   clip: rect(0 0 0 0) !important;
   height: 1px !important;
   margin: -1px !important;
   overflow: hidden !important;
   padding: 0 !important;
   position: absolute !important;
   width: 1px !important;
}

.reqd {
   color: $myRed;
   font-size: 1.25em;
}

.search-form {
   width: 100%;
   margin: 0 auto;

   @include breakpoint(medium) {
      width: 70%;
   }
}