.start_img {
   float: left;
   max-width: 40%;
   vertical-align: middle;
   border: 0;
   margin: 0;
   padding: 0 1em 0 0;
}

.scale-image-center {
   max-width: 80%;
   height: auto;
}

.center-image {
   display: block;
   margin: 0 auto 1em;
   width: 75%;
}

figure {
   text-align: center;
}

figcaption {
   margin-top: 0.5em;
   margin-bottom: 1em;
}

.pcap {
   text-align: left;
   margin: 0.5em auto;
   width: 95%;
}

.resp {
   max-width: 100%;
   height: auto;
}

div.img-block {
   margin-top: 1.5em;
}

img.img-block {
   display: block;
   margin: 0 auto 0.5em auto;
   @include border-radius(12px);
   opacity: 1.0;

   &:hover {
      opacity: 0.5;
   }
}

i.fi-magnifying-glass,
i.fi-home {
   font-size: 1.5em;
}

.learn-more {
   font-size: inherit;
   line-height: 75%;
   cursor: pointer;
   margin-bottom: 1.5em;
   margin-top: 0.5em;
   text-align: center;
}

.image-label {
   text-align: center;
   margin-bottom: 0;
   padding: 0;
   color: $black;
   line-height: 1.1;
}

.frame {
   border: 2px solid $black;
   padding: 5px;
   border-radius: 5px;
}