.card-divider {
   h2 {
      color: $white;
      text-align: center;

      @include breakpoint(medium) {
         font-size: 2.25rem;
      }
   }
}

.card-style {
   margin: 2em auto;
   @include border-radius(8px);
}

.black-divider {
   @include card-divider($background: $black);
   padding: 0.5em;
}

.card-section {
   padding: 4vh;
   border: 2px solid $black;
   @include border-radius(0 0 8px 8px);

   @include breakpoint(medium) {
      padding: 2em 3em;
   }

   li {
      margin-bottom: .5em;
   }

   ul,
   ol {
      margin-left: 2.5em;
   }

   .NoMarg {
      margin-left: 0;
   }

   .refFmt {
      margin-left: 0;
      padding-left: 2em;
   }

   a:link,
   a:visited,
   a:active {
      color: #ff00ff;
      // text-decoration: underline;
   }

   .title {
      font-weight: bold;
   }

   a.title {
      color: #oo59A6;
      text-decoration: underline;
   }
}