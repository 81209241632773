#widemenu.top-bar {
   padding: 0.25em 1.5em;

   a {
      font-size: 1.1rem;
   }

   a:link,
   a:visited,
   a:hover {
      color: $white;
   }
}

.off-canvas {
   padding: 3rem 1rem 1rem 1rem;

   ul.vertical {
      font-weight: bold;
      line-height: .75;

      a {
         color: $white;
      }

      ul.nested {
         font-weight: normal;
         margin-left: 1rem;
      }

      li.menu-text {
         color: $white;
      }
   }
}

.off-canvas-content {
   background-image: url("/assets/img/write013.jpg");
   background-repeat: repeat;
}

.dropdown .menu.vertical li:not(.active) a {
   background-color: $black;
}

span.title-bar-title {
   font-size: 1.1rem;
}